.App-toolbar {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px 1px;
    background-color: #fff;
    height: 58px;
    position: relative;
    z-index: 100;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.App-toolbar > div {
    display: flex;
    align-items: center;
}

.App-logo-primarie {
    display: flex;
    align-items: center;
    bottom: 1rem;
    width: auto;
    white-space: nowrap;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
}

.App-logo-primarie strong {
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    font-style: italic;
    color: #051326;
    font-weight: 900;
    padding-right: 0.5rem;
}

.App-logo-primarie img {
    margin-right: 0.5rem;
    max-width: 36px;
}

.App-nume-primarie {
    padding: 0 0.5rem;
    font-weight: 400;
    font-style: italic;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    line-height: 1.8;
    border-radius: 4px;
    transition: 0.25s all;
}

.App-nume-primarie:hover {
    cursor: pointer;
    background-color: #f9f9f9;
}

.App-logo {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0.5rem;
    color: #051326 !important;
    background: rgba(255, 255, 255, 0.9);
    padding: 0.35rem 0.85rem;
    border-radius: 50px;
    font-size: 1rem;
    font-style: italic;
    white-space: nowrap;
    cursor: pointer;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
        0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.App-logo img {
    /* width: 27px; */
    height: 27px;
    /* margin-right: 0.5rem; */
}

.App-logo span {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    display: none;
}

.App-sidebar {
    position: absolute;
    z-index: 401;
    top: 52px;
    left: 10px;
    bottom: 0;
    padding: 1rem 0 0 0;
    width: 1px;
}

.App-sidebar > .action-button {
    margin-bottom: 0.75rem;
    background: rgba(255, 255, 255, 0.9);
    width: 48px;
    height: 48px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
        0px 1px 3px 0px rgb(0 0 0 / 12%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.App-content {
    min-height: calc(100vh - 58px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    /* filter: grayscale(0); */
    position: relative;
}

.App-cookies {
    position: absolute;
    z-index: 1000;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem 1rem;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 0.9rem;
}

.App-cookies a {
    color: #fff;
}

.App-marker {
    background: rgba(0, 0, 0, 0.25);
    display: inline-block;
    padding: 7px;
    border-radius: 5px;
    white-space: nowrap;
}

.App-link {
    color: #61dafb;
}

#map-wrapper {
    overflow: hidden;
}

.manual-section {
    background-color: #fff;
    transition: all 1s;
    padding-top: 0.25rem;
}

.manual-section img {
    display: block;
    box-sizing: border-box;
    width: 600px;
    max-width: 100%;
    border-radius: 7px;
    margin: 2rem auto;
    border: 3px solid #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
}

.map-bg {
    background: url("/public/map-bg.png");
    background-size: 100%;
}

.list-wrapper {
    text-align: justify;
    line-height: 1.5;
}

.list-wrapper h1 {
    text-align: center;
    font-size: 1.17rem;
}

.list-wrapper h2 {
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
}

.list-wrapper ol {
    text-align: justify;
    counter-reset: item;
    padding: 0;
}

.list-wrapper ol > li {
    counter-increment: item;
    list-style-position: inside;
}

.list-wrapper .draft-wrapper li {
    list-style-position: outside;
}

.list-wrapper ol ol > li {
    display: block;
}

.list-wrapper ol ol li ol {
    padding-left: 1rem;
}

.list-wrapper ol ol > li:before {
    content: counters(item, ".") ". ";
}

.list-wrapper li > ol {
    margin: 1rem 0;
}

.list-wrapper li > ul {
    padding: 0.5rem 0 0.5rem 1rem;
}

.list-wrapper li:before,
.list-wrapper li::marker {
    font-weight: bold;
}

.list-wrapper .draft-wrapper {
    margin: 1rem 0;
}

.list-wrapper ol.roman {
    counter-reset: roman;
}

.list-wrapper ol.roman > li:before {
    counter-increment: roman;
    content: counter(roman, lower-latin) ")";
    padding-right: 5px;
}

.list-wrapper ol.roman li ol {
    counter-reset: inner;
}

.list-wrapper ol.roman ol > li:before {
    counter-increment: inner;
    content: counter(roman, lower-latin) "." counters(inner, ".") ")";
    padding-right: 5px;
}

.draft-wrapper {
    border: 1px solid #b2b2b2;
    border-radius: 4px;
    overflow: hidden;
}

.draft-editor {
    padding: 0 1rem;
    min-height: 200px;
}

.draft-editor.compact {
    min-height: 100px;
}

.rdw-editor-toolbar.draft-toolbar {
    background: #fff;
    border-color: transparent;
    border-bottom: 1px solid #d6d6d6;
}

.draft-wrapper-rlu {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
}

.draft-wrapper-rlu:hover {
    border-color: #212121;
}

.draft-wrapper-rlu.focused {
    border: 1px solid #1876d2;
    box-shadow: 0 0 0 1px #1876d2 inset;
}

.draft-wrapper-rlu .rdw-editor-toolbar.draft-toolbar {
    background: transparent;
}

.draft-wrapper-custom {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
}

.draft-wrapper-custom:hover {
    border-color: #212121;
}

.draft-wrapper-custom.focused {
    border: 1px solid #1876d2;
    box-shadow: 0 0 0 1px #1876d2 inset;
}

.draft-wrapper-custom .rdw-editor-toolbar.draft-toolbar {
    background: transparent;
}

.custom-animated-pin svg {
    transition: 0.2s all;
}

.custom-animated-pin:hover svg {
    transition: 0.2s all;
    margin-top: 2px;
    fill-opacity: 0.9;
}

#maps .leaflet-control-layers,
#amenzi-reclamatii-map .leaflet-control-layers {
    display: none;
}

.leaflet-tooltip.parcelLabel {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 500;
}

@keyframes tooltipanim {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1);
    }
    75% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}

.leaflet-tooltip.overlay-tooltip {
    /* position: relative; */
    border-radius: 50%;
    color: transparent;
    width: 60px;
    height: 60px;
}

.leaflet-tooltip.overlay-tooltip div {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    color: transparent;
    width: 72px;
    height: 72px;
    overflow: hidden;
    /* Bug explained here: https://stackoverflow.com/questions/21087979/probleme-css3-scale-transform-and-overflowhidden-on-safari */
    clip-path: content-box;
}

.leaflet-tooltip.overlay-tooltip div::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    background: url("/public/tooltip-parcels.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 200%;
    animation: tooltipanim 4s;
    animation-iteration-count: 1;
    overflow: hidden;
}

.overlay-element {
    cursor: pointer !important;
}

.legend {
    background: #fff;
    padding: 8px;
    border-radius: 5px;
}

.leaflet-marker-icon.custom-leaflet-dot:hover {
    opacity: 1 !important;
}

.fade-enter td {
    opacity: 0;
}

.fade-enter-active td {
    opacity: 1;
    transition: all 400ms ease-in;
}
.fade-exit div {
    opacity: 1;
}
.fade-exit-active div {
    opacity: 0;
    transition: all 400ms ease-in;
}

.leaflet-buildings-pane canvas,
.leaflet-labels-pane canvas {
    pointer-events: none;
}

.leaflet-container .leaflet-control-mouseposition {
    background-color: rgba(255, 255, 255, 0.7);
    /* box-shadow: 0 0 5px #bbb; */
    padding: 0 5px;
    margin: 0;
    position: relative;
    bottom: 2.5rem;
    color: #333;
    font: 11px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    min-width: 143px;
    text-align: center;
    border-radius: 40px;
}

.leaflet-touch .leaflet-control-attribution {
    display: none;
}

#maps svg {
    shape-rendering: optimizeSpeed;
}

.leaflet-tooltip-pane {
    text-rendering: optimizeSpeed;
}

/* Print Styles */

.map-print-wrapper {
    background-color: #525659;
    padding: 12px 0;
}

.map-print-wrapper > div {
    max-width: 1032px;
    /* min-width: 1032px; */
    padding: 24px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 6px;
    border-radius: 0;
}

@media print {
    .map-print-wrapper {
        background-color: #525659;
        padding: 0;
        margin: 0;
    }
    .map-print-wrapper > div {
        margin: 0;
        box-shadow: none;
    }
}
